/* styles.module.css */

.postCard {
  border: 1px solid #ddd;
  margin: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
  /* height: auto; */

  /* Add box shadow for a subtle lift effect on hover */
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }}
  
  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-right: 10px;
  }

  .postTitle {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .postContent {
 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  h2 {
    margin-bottom: 5px;
  }
  
  .postActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  /* Styles for the action buttons */
  .postActions button {
    margin-right: 10px;
  }