.button-container{
    margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.card-company{
    background-color: #ffffff;
    padding: 20px;
    border : 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 10px 10px 8px #888888;

}