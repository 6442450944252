.spinner{
    margin-left: 10px;
}

.message-colour{
    color: green;
    margin: 0;
    padding: 5px;
}

.uploader{
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
  
}